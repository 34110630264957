export var Region;
(function (Region) {
    Region["Kanto"] = "kanto";
    Region["Johto"] = "johto";
    Region["Hoenn"] = "hoenn";
    Region["Sinnoh"] = "sinnoh";
    Region["Unova"] = "unova";
    Region["Kalos"] = "kalos";
    Region["Alola"] = "alola";
    Region["Orre"] = "orre";
    Region["Galar"] = "galar";
    Region["Hisui"] = "hisui";
    Region["Paldea"] = "Paldea";
    Region["Other"] = "other";
})(Region || (Region = {}));
export const getGameRegion = (game) => {
    const kanto = [
        'Red',
        'Blue',
        'Yellow',
        'Green',
        'FireRed',
        'LeafGreen',
        'Let\'s Go Eevee',
        'Let\'s Go Pikachu',
    ];
    const johto = ['Gold', 'Silver', 'Crystal', 'HeartGold', 'SoulSilver'];
    const hoenn = ['Ruby', 'Sapphire', 'OmegaRuby', 'AlphaSapphire', 'Emerald'];
    const sinnoh = ['Diamond', 'Pearl', 'Platinum', 'Brilliant Diamond', 'Shining Pearl'];
    const unova = ['Black', 'Black 2', 'White', 'White 2'];
    const kalos = ['X', 'Y'];
    const alola = ['Sun', 'Ultra Sun', 'Ultra Moon', 'Moon'];
    const orre = ['XD Gale of Darkness', 'Colosseum'];
    const galar = ['Sword', 'Shield'];
    const hisui = ['Legends: Arceus'];
    const paldea = ['Scarlet', 'Violet'];
    if (kanto.includes(game))
        return Region.Kanto;
    if (johto.includes(game))
        return Region.Johto;
    if (hoenn.includes(game))
        return Region.Hoenn;
    if (sinnoh.includes(game))
        return Region.Sinnoh;
    if (unova.includes(game))
        return Region.Unova;
    if (kalos.includes(game))
        return Region.Kalos;
    if (alola.includes(game))
        return Region.Alola;
    if (orre.includes(game))
        return Region.Orre;
    if (galar.includes(game))
        return Region.Galar;
    if (hisui.includes(game))
        return Region.Hisui;
    if (paldea.includes(game))
        return Region.Paldea;
    return Region.Other;
};
