export var TemplateName;
(function (TemplateName) {
    TemplateName["DefaultLight"] = "Default Light";
    TemplateName["DefaultDark"] = "Default Dark";
    TemplateName["Cards"] = "Cards";
    TemplateName["Generations"] = "Generations";
    TemplateName["GenerationsClassic"] = "Generations Classic";
    TemplateName["Hexagons"] = "Hexagons";
    TemplateName["Compact"] = "Compact";
    TemplateName["CompactWithIcons"] = "Compact with Icons";
    TemplateName["Blank"] = "Blank";
})(TemplateName || (TemplateName = {}));
export const listOfThemes = [
    TemplateName.DefaultLight,
    TemplateName.DefaultDark,
    TemplateName.Cards,
    TemplateName.Generations,
    TemplateName.GenerationsClassic,
    TemplateName.Hexagons,
    TemplateName.Compact,
    TemplateName.CompactWithIcons,
    TemplateName.Blank,
].sort();
