export const EDIT_CUSTOM_MOVE_MAP = 'EDIT_CUSTOM_MOVE_MAP';
export const editCustomMoveMap = (moveType, moveName) => {
    return {
        type: EDIT_CUSTOM_MOVE_MAP,
        moveType,
        moveName,
    };
};
export const DELETE_CUSTOM_MOVE = 'DELETE_CUSTOM_MOVE';
export const deleteCustomMove = (id) => ({ type: DELETE_CUSTOM_MOVE, id });
