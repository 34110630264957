export const UPDATE_EDITOR_HISTORY = 'UPDATE_EDITOR_HISTORY';
export const updateEditorHistory = (present) => {
    return {
        type: UPDATE_EDITOR_HISTORY,
        present,
    };
};
export const UNDO_EDITOR_HISTORY = 'UNDO_EDITOR_HISTORY';
export const undoEditorHistory = (present) => {
    return {
        type: UNDO_EDITOR_HISTORY,
        present,
    };
};
export const REDO_EDITOR_HISTORY = 'REDO_EDITOR_HISTORY';
export const redoEditorHistory = (present) => {
    return {
        type: REDO_EDITOR_HISTORY,
        present,
    };
};
