export const styleDefaults = {
    accentColor: '#111111',
    backgroundImage: '',
    bgColor: '#383840',
    customCSS: '',
    displayBadges: true,
    displayRules: false,
    editorDarkMode: false,
    font: 'Open Sans',
    iconsNextToTeamPokemon: false,
    imageStyle: 'round',
    itemStyle: 'outer glow',
    pokeballStyle: 'outer glow',
    iconRendering: 'auto',
    grayScaleDeadPokemon: false,
    minimalBoxedLayout: false,
    minimalTeamLayout: false,
    minimalDeadLayout: false,
    minimalChampsLayout: true,
    movesPosition: 'horizontal',
    oldMetLocationFormat: false,
    resultHeight: '900',
    resultWidth: '1200',
    scaleSprites: false,
    showPokemonMoves: true,
    spritesMode: false,
    teamImages: 'standard',
    teamPokemonBorder: true,
    template: 'Default Dark',
    tileBackground: false,
    topHeaderColor: '#333333',
    trainerSectionOrientation: 'horizonal',
    useSpritesForChampsPokemon: false,
    boxedPokemonPerLine: 6,
    displayGameOriginForBoxedAndDead: false,
    displayBackgroundInsteadOfBadge: false,
    displayExtraData: true,
    useAutoHeight: true,
    usePokemonGBAFont: false,
    displayItemAsText: false,
    displayRulesLocation: 'bottom',
    trainerWidth: '20%',
    trainerHeight: '100%',
    trainerAuto: true,
    displayStats: false,
    linkedPokemonText: 'Linked To',
    statsOptions: {
        averageLevel: false,
        averageLevelDetailed: false,
        mostCommonKillers: false,
        mostCommonTypes: false,
        shiniesCaught: false,
    },
    customTeamHTML: '',
    zoomLevel: 1,
};
