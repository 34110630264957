export const listOfTrainers = [
    'dawn',
    'may',
    'sun',
    'moon',
    'lyra',
    'nate',
    'rosa',
    'ethan',
    'red',
    'leaf',
    'brendan',
];
