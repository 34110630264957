var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
}
export function wrapImageInCORS(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${process.env.CORS_ANYWHERE_URL}/${url}`, {
            mode: 'cors',
            'X-Requested-With': 'XMLHttpRequest',
        });
        const img = yield response.blob();
        return `url(${yield fileToBase64(img)})`;
    });
}
export function wrapImageInCORSPlain(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${process.env.CORS_ANYWHERE_URL}/${url}`, {
            mode: 'cors',
            'X-Requested-With': 'XMLHttpRequest',
        });
        const img = yield response.blob();
        return `${yield fileToBase64(img)}`;
    });
}
