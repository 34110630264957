export const DELETE_POKEMON = 'DELETE_POKEMON';
export const deletePokemon = (id) => {
    return {
        type: DELETE_POKEMON,
        id,
    };
};
export const CLEAR_BOX = 'CLEAR_BOX';
export const clearBox = (name) => {
    return {
        type: CLEAR_BOX,
        name,
    };
};
