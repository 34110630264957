export const listOfPokeballs = [
    'beast',
    'cherish',
    'dive',
    'dream',
    'dusk',
    'fast',
    'friend',
    'great',
    'heal',
    'heavy',
    'level',
    'love',
    'lure',
    'luxury',
    'master',
    'moon',
    'nest',
    'net',
    'park',
    'poke',
    'premier',
    'quick',
    'repeat',
    'safari',
    'sport',
    'timer',
    'ultra',
];
