export var GenderTransformType;
(function (GenderTransformType) {
    GenderTransformType[GenderTransformType["Standard"] = 0] = "Standard";
    GenderTransformType[GenderTransformType["Sugimori"] = 1] = "Sugimori";
    GenderTransformType[GenderTransformType["Dreamworld"] = 2] = "Dreamworld";
    GenderTransformType[GenderTransformType["Sprite"] = 3] = "Sprite";
})(GenderTransformType || (GenderTransformType = {}));
export const significantGenderDifferenceList = [
    'Hippowdon',
    'Unfezant',
    'Frillish',
    'Jellicent',
    'Pyroar',
    'Meowstic',
    'Indeedee',
    'Oinkologne',
    'Basculegion',
];
