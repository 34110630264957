import { omit } from 'ramda';
export const gameNameSelector = (state) => state.game.name;
export const minimizedSelector = (state) => state.editor.minimized;
export const editorModeSelector = (state) => state.style.editorDarkMode;
export const linkedPokemonSelector = (pokemon) => (state) => state.pokemon.find((p) => p.id === (pokemon === null || pokemon === void 0 ? void 0 : pokemon.linkedTo));
export const isDarkModeSelector = (state) => state.style.editorDarkMode;
export const updaterSelector = (state) => {
    var _a;
    return ({
        present: omit(['editorHistory'], state),
        lrt: (_a = state === null || state === void 0 ? void 0 : state.editorHistory) === null || _a === void 0 ? void 0 : _a.lastRevisionType,
    });
};
export const appSelector = (state) => ({
    style: state.style,
    view: state.view,
    editor: state.editor,
});
export const resultSelector = (state) => ({
    pokemon: state.pokemon,
    game: state.game,
    trainer: state.trainer,
    style: state.style,
    box: state.box,
    rules: state.rules,
    editor: state.editor,
});
