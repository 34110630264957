export const ADD_BOX = 'ADD_BOX';
export function addBox({ name, background, inheritFrom }) {
    return {
        type: ADD_BOX,
        name,
        background,
        inheritFrom,
    };
}
export const UPDATE_BOXES = 'UPDATE_BOXES';
export function updateBoxes() {
    return {
        type: UPDATE_BOXES,
    };
}
