import { typeToColor } from 'utils';
export const getBackgroundGradient = (typeA, typeB, customTypes) => {
    if (typeB == null) {
        if (typeA == null) {
            return 'transparent';
        }
        else {
            return `linear-gradient(to right, ${typeToColor(typeA, customTypes)}, ${typeToColor(typeA, customTypes)}`;
        }
    }
    else {
        return `linear-gradient(to right, ${typeToColor(typeA, customTypes)}, ${typeToColor(typeB, customTypes)}`;
    }
};
