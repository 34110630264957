export var Generation;
(function (Generation) {
    Generation[Generation["Gen1"] = 1] = "Gen1";
    Generation[Generation["Gen2"] = 2] = "Gen2";
    Generation[Generation["Gen3"] = 3] = "Gen3";
    Generation[Generation["Gen4"] = 4] = "Gen4";
    Generation[Generation["Gen5"] = 5] = "Gen5";
    Generation[Generation["Gen6"] = 6] = "Gen6";
    Generation[Generation["Gen7"] = 7] = "Gen7";
    Generation[Generation["Gen8"] = 8] = "Gen8";
    Generation[Generation["Gen9"] = 9] = "Gen9";
})(Generation || (Generation = {}));
export const getGameGeneration = (game) => {
    const Gen1 = ['Red', 'Blue', 'Yellow', 'Green'];
    const Gen2 = ['Gold', 'Silver', 'Crystal'];
    const Gen3 = [
        'Ruby',
        'Sapphire',
        'Emerald',
        'FireRed',
        'LeafGreen',
        'Colosseum',
        'XD Gale of Darkness',
    ];
    const Gen4 = ['Diamond', 'Pearl', 'Platinum', 'HeartGold', 'SoulSilver'];
    const Gen5 = ['Black', 'Black 2', 'White', 'White 2'];
    const Gen6 = ['X', 'Y', 'OmegaRuby', 'AlphaSapphire'];
    const Gen7 = [
        'None',
        'Sun',
        'Moon',
        'Ultra Moon',
        'Ultra Sun',
        'Let\'s Go Eevee',
        'Let\'s Go Pikachu',
    ];
    const Gen8 = ['Sword', 'Shield', 'Brilliant Diamond', 'Shining Pearl', 'Legends: Arceus'];
    const Gen9 = ['Scarlet', 'Violet', 'Legends: Z-A'];
    if (Gen1.includes(game))
        return Generation.Gen1;
    if (Gen2.includes(game))
        return Generation.Gen2;
    if (Gen3.includes(game))
        return Generation.Gen3;
    if (Gen4.includes(game))
        return Generation.Gen4;
    if (Gen5.includes(game))
        return Generation.Gen5;
    if (Gen6.includes(game))
        return Generation.Gen6;
    if (Gen7.includes(game))
        return Generation.Gen7;
    if (Gen8.includes(game))
        return Generation.Gen8;
    if (Gen9.includes(game))
        return Generation.Gen9;
    return Generation.Gen8;
};
