export const listOfGames = [
    'None',
    'Red',
    'Blue',
    'Green',
    'Yellow',
    'Gold',
    'Silver',
    'Crystal',
    'Ruby',
    'Sapphire',
    'FireRed',
    'LeafGreen',
    'Emerald',
    'Diamond',
    'Pearl',
    'Platinum',
    'HeartGold',
    'SoulSilver',
    'Black',
    'White',
    'Black 2',
    'White 2',
    'X',
    'Y',
    'OmegaRuby',
    'AlphaSapphire',
    'Sun',
    'Moon',
    'Ultra Sun',
    'Ultra Moon',
    'Colosseum',
    'XD Gale of Darkness',
    'Let\'s Go Eevee',
    'Let\'s Go Pikachu',
    'Sword',
    'Shield',
    'Brilliant Diamond',
    'Shining Pearl',
    'Legends: Arceus',
    'Scarlet',
    'Violet',
    'Legends: Z-A'
];
