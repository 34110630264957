export const listOfItems = [
    'Absorb Bulb',
    'Adamant Orb',
    'Adrenaline Orb',
    'Air Balloon',
    'Amulet Coin',
    'Assault Vest',
    'Big Root',
    'Binding Band',
    'Black Belt',
    'Black Glasses',
    'Black Sludge',
    'Blue Orb',
    'Bright Powder',
    'Burn Drive',
    'Cell Battery',
    'Charcoal',
    'Chill Drive',
    'Choice Band',
    'Choice Scarf',
    'Choice Specs',
    'Cleanse Tag',
    'Damp Rock',
    'Destiny Knot',
    'Douse Drive',
    'Dragon Fang',
    'Eject Button',
    'Electric Seed',
    'Everstone',
    'Eviolite',
    'Exp Share',
    'Expert Belt',
    'Fairy Feather',
    'Flame Orb',
    'Float Stone',
    'Focus Band',
    'Focus Sash',
    'Grassy Seed',
    'Grip Claw',
    'Griseous Orb',
    'Hard Stone',
    'Heat Rock',
    'Icy Rock',
    'Iron Ball',
    'Kings Rock',
    'Lagging Tail',
    'Leftovers',
    'Life Orb',
    'Light Ball',
    'Light Clay',
    'Lucky Egg',
    'Lucky Punch',
    'Luminous Moss',
    'Lustrous Orb',
    'Magnet',
    'Mental Herb',
    'Metal Coat',
    'Metal Powder',
    'Metronome',
    'Miracle Seed',
    'Misty Seed',
    'Muscle Band',
    'Mystic Water',
    'Never Melt Ice',
    'Oval Stone',
    'Poison Barb',
    'Power Herb',
    'Protective Pads',
    'Psychic Seed',
    'Quick Claw',
    'Quick Powder',
    'Razor Claw',
    'Razor Fang',
    'Red Card',
    'Red Orb',
    'Ring Target',
    'Rocky Helmet',
    'Safety Helmet',
    'Scope Lens',
    'Sharp Beak',
    'Shed Shell',
    'Shell Bell',
    'Shock Drive',
    'Silk Scarf',
    'Silver Powder',
    'Smoke Ball',
    'Smooth Rock',
    'Snowball',
    'Soft Sand',
    'Soothe Bell',
    'Soul Dew',
    'Spell Tag',
    'Stick',
    'Sticky Barb',
    'Terrain Extender',
    'Thick Club',
    'Toxic Orb',
    'Twisted Spoon',
    'Weakness Policy',
    'White Herb',
    'Wide Lens',
    'Wise Glasses',
    'Zoom Lens',
    'Aguav Berry',
    'Apicot Berry',
    'Aspear Berry',
    'Babiri Berry',
    'Belue Berry',
    'Bluk Berry',
    'Charti Berry',
    'Cheri Berry',
    'Chesto Berry',
    'Chilan Berry',
    'Chople Berry',
    'Coba Berry',
    'Colbur Berry',
    'Cornn Berry',
    'Custap Berry',
    'Durin Berry',
    'Enigma Berry',
    'Figy Berry',
    'Ganlon Berry',
    'Grepa Berry',
    'Haban Berry',
    'Hondew Berry',
    'Iapapa Berry',
    'Jaboca Berry',
    'Kasib Berry',
    'Kebia Berry',
    'Kee Berry',
    'Kelpsy Berry',
    'Lansat Berry',
    'Liechi Berry',
    'Lum Berry',
    'Mago Berry',
    'Magost Berry',
    'Maranga Berry',
    'Micle Berry',
    'Nomel Berry',
    'Occa Berry',
    'Oran Berry',
    'Pamtre Berry',
    'Passho Berry',
    'Payapa Berry',
    'Pecha Berry',
    'Persim Berry',
    'Petaya Berry',
    'Pinap Berry',
    'Pomeg Berry',
    'Qualot Berry',
    'Rabuta Berry',
    'Rawst Berry',
    'Razz Berry',
    'Rindo Berry',
    'Roseli Berry',
    'Rowap Berry',
    'Salac Berry',
    'Shuca Berry',
    'Sitrus Berry',
    'Spelon Berry',
    'Starf Berry',
    'Tamato Berry',
    'Tanga Berry',
    'Wacan Berry',
    'Watmel Berry',
    'Wepear Berry',
    'Wiki Berry',
    'Berry',
    'Bitter Berry',
    'Burnt Berry',
    'Gold Berry',
    'Ice Berry',
    'Mint Berry',
    'MiracleBerry',
    'MysteryBerry',
    'PRZCureBerry',
    'PSNCureBerry',
    'Bug Gem',
    'Dark Gen',
    'Dragon Gem',
    'Electric Gem',
    'Fairy Gem',
    'Fighting Gem',
    'Fire Gem',
    'Flying Gem',
    'Ghost Gem',
    'Grass Gem',
    'Ground Gem',
    'Ice Gem',
    'Normal Gem',
    'Poison Gem',
    'Psychic Gem',
    'Rock Gem',
    'Steel Gem',
    'Water Gem',
    'Draco Plate',
    'Dread Plate',
    'Earth Plate',
    'Fist Plate',
    'Flame Plate',
    'Icicle Plate',
    'Insect Plate',
    'Iron Plate',
    'Meadow Plate',
    'Mind Plate',
    'Pixie Plate',
    'Sky Plate',
    'Splash Plate',
    'Spooky Plate',
    'Stone Plate',
    'Toxic Plate',
    'Zap Plate',
    'Blue Scarf',
    'Green Scarf',
    'Pink Scarf',
    'Red Scarf',
    'Yellow Scarf',
    'Macho Brace',
    'Power Anklet',
    'Power Band',
    'Power Belt',
    'Power Bracer',
    'Power Lens',
    'Power Weight',
    'Adamant Mint',
    'Bach\'s Food Tin',
    'Band Autograph',
    'Berry Sweet',
    'Blunder Policy',
    'Bob\'s Food Tin',
    'Boiled Egg',
    'Bold Mint',
    'Brave Mint',
    'Bread',
    'Brittle Bones',
    'Calm Mint',
    'Catching Charm',
    'Chipped Pot',
    'Clover Sweet',
    'Coconut Milk',
    'Cracked Pot',
    'Dynamax Band',
    'Dynamax Candy',
    'Eject Pack',
    'Endorsement',
    'Exp. Candy L',
    'Exp. Candy M',
    'Exp. Candy S',
    'Exp. Candy XL',
    'Exp. Candy XS',
    'Fancy Apple',
    'Fishing Rod',
    'Flower Sweet',
    'Fossilized Bird',
    'Fossilized Dino',
    'Fossilized Drake',
    'Fossilized Fish',
    'Fresh Cream',
    'Fried Food',
    'Fruit Bunch',
    'Gentle Mint',
    'Gigantamix',
    'Hasty Mint',
    'Heavy-Duty Boots',
    'Hit-tech Earbuds',
    'Impish Mint',
    'Instant Noodles',
    'Jolly Mint',
    'Large Leek',
    'Lax Mint',
    'Lonely Mint',
    'Love Sweet',
    'Mild Mint',
    'Mixed Mushrooms',
    'Modest Mint',
    'Moomoo Cheese',
    'Naive Mint',
    'Naughty Mint',
    'Old Letter',
    'Pack of Potatoes',
    'Packaged Curry',
    'Pasta',
    'Pokemon Box Link',
    'Precooked Burger',
    'Pungent Root',
    'Quiet Mint',
    'Rash Mint',
    'Relaxed Mint',
    'Ribbon Sweet',
    'Room Service',
    'Rotom Bike',
    'Rotom Catalog',
    'Rusted Shield',
    'Rusted Sword',
    'Salad Mix',
    'Sassy Mix',
    'Sausages',
    'Serious Mint',
    'Smoke-Pke Tail',
    'Sonia\'s Book',
    'Spice Mix',
    'Star Sweet',
    'Strawberry Sweet',
    'Sweet Apple',
    'Tart Apple',
    'Throat Spray',
    'Timid Mint',
    'Tin of Beans',
    'Utility Umbrella',
    'Wishing Chip',
    'Wishing Piece',
    'Wishing Star',
    'Sea Incense',
    'Lax Incense',
    'Rose Incense',
    'Pure Incense',
    'Rock Incense',
    'Odd Incense',
    'Luck Incense',
    'Full Incense',
    'Wave Incense',
    'Abomasite',
    'Absolite',
    'Aerodactylite',
    'Aggronite',
    'Alakazite',
    'Altarianite',
    'Ampharosite',
    'Audinite',
    'Banetite',
    'Beedrillite',
    'Blastoisinite',
    'Blazikenite',
    'Cameruptite',
    'Charizardite X',
    'Charizardite Y',
    'Diancite',
    'Galladite',
    'Garchompite',
    'Gardevoirite',
    'Gengarite',
    'Glalitite',
    'Gyaradosite',
    'Heracronite',
    'Houndoominite',
    'Kangaskhanite',
    'Latiasite',
    'Latiosite',
    'Lopunnite',
    'Lucarionite',
    'Manectite',
    'Mawilite',
    'Medichamite',
    'Metagrossite',
    'Mewtwonite X',
    'Mewtwonite Y',
    'Pidgeotite',
    'Pinsirite',
    'Sablenite',
    'Salamencite',
    'Sceptilite',
    'Scizorite',
    'Sharpedonite',
    'Slowbronite',
    'Steelixite',
    'Swampertite',
    'Tyranitarite',
    'Venusaurite',
    'Buginium Z',
    'Dragonium Z',
    'Electrium Z',
    'Fairium Z',
    'Fightinium Z',
    'Firium Z',
    'Flyinium Z',
    'Ghostium Z',
    'Grassium Z',
    'Groundium Z',
    'Icium Z',
    'Normalium Z',
    'Poisinium Z',
    'Psychium Z',
    'Rockium Z',
    'Steelium Z',
    'Waterium Z',
    'Aloraichium Z',
    'Decidium Z',
    'Eevium Z',
    'Incinium Z',
    'Kommonium Z',
    'Lunalium Z',
    'Lycanium Z',
    'Marshadium Z',
    'Mewnium Z',
    'Mimikium Z',
    'Pikanium Z',
    'Pikashunium Z',
    'Primarium Z',
    'Snorlium Z',
    'Solganium Z',
    'Tapunium Z',
    'Ultranecrozium Z',
    'Max Honey',
    'Max Mushrooms',
    'Galarica Twig',
    'Galarica Cuff',
    'Style Card',
    'Armor Pass',
    'Rotom Bike White',
    'Rotobm Bike Black',
    'Exp. Charm',
    'Armorite Ore',
    'Mark Charm',
    'Mirror Herb',
    'Covert Cloak',
    'Loaded Dice',
    'Tiny Bamboo Shoot',
    'Big Bamboo Shoot',
    'Scroll of Darkness',
    'Scroll of Waters',
    'Booster Energy',
    'Ability Shield',
    'Clear Amulet',
    'Punching Glove',
    'Auspicious Armor',
    'Malicious Armor',
    'Leader\'s Crest',
];
