export const movesByType = {
    Bug: [
        'Powder',
        'Fell Stinger',
        'Twineedle',
        'Tail Glow',
        'Struggle Bug',
        'String Shot',
        'Sticky Web',
        'Attack Order',
        'Steamroller',
        'Spider Web',
        'Silver Wind',
        'Signal Beam',
        'Savage Spin-Out',
        'Rage Powder',
        'Quiver Dance',
        'U-turn',
        'Pollen Puff',
        'Pin Missile',
        'Defend Order',
        'X-Scissor',
        'Megahorn',
        'Lunge',
        'Leech Life',
        'Bug Bite',
        'Bug Buzz',
        'Infestation',
        'Heal Order',
        'Fury Cutter',
        'First Impression',
        'HP Bug',
        'Skitter Smack',
        'Silk Trap',
        'Pounce',
    ],
    Dark: [
        'Night Slash',
        'Quash',
        'Pursuit',
        'Punishment',
        'Power Trip',
        'Bite',
        'Black Hole Eclipse',
        'Assurance',
        'Topsy-Turvy',
        'Throat Chop',
        'Payback',
        'Parting Shot',
        'Thief',
        'Taunt',
        'Night Daze',
        'Nasty Plot',
        'Memento',
        'Crunch',
        'Malicious Moonsault',
        'Brutal Swing',
        'Snatch',
        'Snarl',
        'Dark Void',
        'Switcheroo',
        'Knock Off',
        'Sucker Punch',
        'Hyperspace Fury',
        'Hone Claws',
        'Darkest Lariat',
        'Dark Pulse',
        'Foul Play',
        'Fling',
        'Flatter',
        'Beat Up',
        'Torment',
        'Embargo',
        'Feint Attack',
        'Faint Attack',
        'Fake Tears',
        'HP Dark',
        'Jaw Lock',
        'Obstruct',
        'False Surrender',
        'Lash Out',
        'Wicked Blow',
        'Baddy Bad',
        'Fiery Wrath',
        'Kowtow Cleave',
        'Ruination',
        'Comeuppance',
        'Wicked Torque',
    ],
    Dragon: [
        'Dragon Claw',
        'Clanging Scales',
        'Dragon Tail',
        'Dragon Rush',
        'Dragon Rage',
        'Core Enforcer',
        'Dragon Pulse',
        'Dragon Hammer',
        'Dragon Dance',
        'Dual Chop',
        'DragonBreath',
        'Dragon Breath',
        'Draco Meteor',
        'Devastating Drake',
        'Outrage',
        'Roar of Time',
        'Spacial Rend',
        'Twister',
        'Clangorous Soulblaze',
        'HP Dragon',
        'Dynamax Cannon',
        'Dragon Darts',
        'Eternabeam',
        'Breaking Swipe',
        'Clangorous Soul',
        'Scale Shot',
        'Dragon Energy',
        'Order Up',
        'Glaive Rush',
    ],
    Electric: [
        '10,000,000 Volt Thunderbolt',
        'Buzzy Buzz',
        'Thunder',
        'Zap Cannon',
        'Zing Zap',
        'Spark',
        'Wild Charge',
        'Magnetic Flux',
        'Magnet Rise',
        'Volt Tackle',
        'Electroweb',
        'Electro Ball',
        'Electrify',
        'Electric Terrain',
        'Eerie Impulse',
        'Volt Switch',
        'Thunderbolt',
        'Thunder Wave',
        'Shock Wave',
        'Thunder Shock',
        'Thunder Shock',
        'Ion Deluge',
        'Stoked Sparksurfer',
        'Parabolic Charge',
        'ThunderPunch',
        'Thunder Punch',
        'Bolt Strike',
        'Gigavolt Havoc',
        'Discharge',
        'Fusion Bolt',
        'Catastropika',
        'Nuzzle',
        'Charge',
        'Charge Beam',
        'Thunder Fang',
        'Plasma Fists',
        'HP Electric',
        'Bolt Beak',
        'Aura Wheel',
        'Overdrive',
        'Rising Voltage',
        'Zippy Zap',
        'Pika Papow',
        'Thunder Cage',
        'Electro Drift',
        'Double Shock',
    ],
    Fairy: [
        'Dazzling Gleam',
        'Sweet Kiss',
        'Disarming Voice',
        'Aromatic Mist',
        'Baby-Doll Eyes',
        'Draining Kiss',
        'Light of Ruin',
        'Fairy Lock',
        'Fairy Wind',
        'Guardian of Alola',
        'Twinkle Tackle',
        'Crafty Shield',
        'Geomancy',
        'Misty Terrain',
        'Moonblast',
        'Moonlight',
        'Nature\'s Madness',
        'Play Rough',
        'Fleur Cannon',
        'Floral Healing',
        'Flower Shield',
        'Charm',
        'Let\'s Snuggle Forever',
        'Decorate',
        'Spirit Break',
        'Strange Steam',
        'Misty Explosion',
        'Sparkly Swirl',
        'Magical Torque',
    ],
    Fighting: [
        'Seismic Toss',
        'Secret Sword',
        'Jump Kick',
        'Karate Chop',
        'Sacred Sword',
        'Bulk Up',
        'Hi Jump Kick',
        'High Jump Kick',
        'Rolling Kick',
        'AncientPower',
        'Rock Smash',
        'Reversal',
        'Arm Thrust',
        'Cross Chop',
        'Revenge',
        'Close Combat',
        'Quick Guard',
        'Power-Up Punch',
        'Hammer Arm',
        'Low Kick',
        'Final Gambit',
        'Low Sweep',
        'Vacuum Wave',
        'Submission',
        'Mach Punch',
        'Brick Break',
        'Mat Block',
        'Circle Throw',
        'Superpower',
        'Storm Throw',
        'Double Kick',
        'Aura Sphere',
        'Counter',
        'All-Out Pummeling',
        'Sky Uppercut',
        'Detect',
        'Drain Punch',
        'Wake-Up Slap',
        'Vital Throw',
        'Flying Press',
        'Focus Blast',
        'Triple Kick',
        'Focus Punch',
        'DynamicPunch',
        'Dynamic Punch',
        'Force Palm',
        'HP Fighting',
        'Body Press',
        'Meteor Assault',
        'No Retreat',
        'Octolock',
        'Coaching',
        'Thunderous Kick',
        'Axe Kick',
        'Collision Course',
        'Combat Torque',
    ],
    Fire: [
        'Fire Blast',
        'Overheat',
        'Flare Blitz',
        'Mystical Fire',
        'Flamethrower',
        'Fusion Flare',
        'Flame Wheel',
        'Flame Charge',
        'Flame Burst',
        'Blaze Kick',
        'Fire Spin',
        'Fire Punch',
        'V-create',
        'Magma Storm',
        'Fire Pledge',
        'Fire Lash',
        'Fire Fang',
        'Blue Flare',
        'Sizzly Slide',
        'Fiery Dance',
        'Blast Burn',
        'Eruption',
        'Lava Plume',
        'Sacred Fire',
        'Will-O-Wisp',
        'Searing Shot',
        'Ember',
        'Heat Crash',
        'Heat Wave',
        'Shell Trap',
        'Inferno Overdrive',
        'Inferno',
        'Incinerate',
        'Sunny Day',
        'Burn Up',
        'Mind Blown',
        'HP Fire',
        'Pyro Ball',
        'Burning Jealousy',
        'Torch Song',
        'Bitter Blade',
        'Armor Cannon',
        'Blazing Torque',
    ],
    Flying: [
        'FeatherDance',
        'Feather Dance',
        'Floaty Tail',
        'Air Slash',
        'Roost',
        'Supersonic Skystrike',
        'Sky Drop',
        'Aeroblast',
        'Gust',
        'Wing Attack',
        'Oblivion Wing',
        'Sky Attack',
        'Dragon Ascent',
        'Peck',
        'Tailwind',
        'Chatter',
        'Defog',
        'Air Cutter',
        'Mirror Move',
        'Aerial Ace',
        'Fly',
        'Acrobatics',
        'Bounce',
        'Brave Bird',
        'Drill Peck',
        'Beak Blast',
        'Hurricane',
        'Pluck',
        'HP Flying',
        'Dual Wingbeat',
    ],
    Ghost: [
        'Nightmare',
        'Night Shade',
        'Never-Ending Nightmare',
        'Phantom Force',
        'Shadow Punch',
        'Shadow Force',
        'Moongeist Beam',
        'Shadow Claw',
        'Lick',
        'Shadow Bone',
        'Confuse Ray',
        'Shadow Ball',
        'Sinister Arrow Raid',
        'Soul-Stealing 7-Star Strike',
        'Astonish',
        'Spite',
        'Curse',
        'Spirit Shackle',
        'Hex',
        'Trick-or-Treat',
        'Ominous Wind',
        'Destiny Bond',
        'Spectral Thief',
        'Shadow Sneak',
        'Grudge',
        'Menacing Moonraze Maelstrom',
        'HP Ghost',
        'Poltergeist',
        'Astral Barrage',
        'Last Respects',
        'Rage Fist',
    ],
    Grass: [
        'Frenzy Plant',
        'Strength Sap',
        'Bullet Seed',
        'Sappy Seed',
        'Spore',
        'Spiky Shield',
        'Solar Blade',
        'SolarBeam',
        'Solar Beam',
        'Cotton Spore',
        'Aromatherapy',
        'Stun Spore',
        'Sleep Powder',
        'Cotton Guard',
        'Worry Seed',
        'Seed Flare',
        'Ingrain',
        'Seed Bomb',
        'Vine Whip',
        'Energy Ball',
        'Razor Leaf',
        'Leaf Blade',
        'Leaf Storm',
        'Leaf Tornado',
        'Leafage',
        'Leech Seed',
        'Synthesis',
        'Power Whip',
        'Absorb',
        'Magical Leaf',
        'Grassy Terrain',
        'Grasswhistle',
        'Grass Whistle',
        'Wood Hammer',
        'Grass Pledge',
        'Mega Drain',
        'Grass Knot',
        'Petal Dance',
        'Giga Drain',
        'Petal Blizzard',
        'Bloom Doom',
        'Forest\'s Curse',
        'Horn Leech',
        'Needle Arm',
        'Trop Kick',
        'HP Grass',
        'Grav Apple',
        'Apple Acid',
        'Branch Poke',
        'Drum Beating',
        'Snap Trap',
        'Grassy Glide',
        'Jungle Healing',
        'Spicy Extract',
        'Flower Trick',
        'Trailblaze',
    ],
    Ground: [
        'Sand-Attack',
        'Sand Attack',
        'Mud-Slap',
        'Mud Sport',
        'Bone Club',
        'Mud Shot',
        'Mud Bomb',
        'Magnitude',
        'Fissure',
        'Precipice Blades',
        'Tectonic Rage',
        'Dig',
        'Rototiller',
        'Land\'s Wrath',
        'Bone Rush',
        'Sand Tomb',
        'Bonemerang',
        'Bulldoze',
        'High Horsepower',
        'Earthquake',
        'Earth Power',
        'Drill Run',
        'Shore Up',
        'Thousand Waves',
        'Spikes',
        'Thousand Arrows',
        'Stomping Tantrum',
        'HP Ground',
        'Scorching Sands',
    ],
    Ice: [
        'Hail',
        'Frost Breath',
        'Haze',
        'Subzero Slammer',
        'Sheer Cold',
        'Icy Wind',
        'Mist',
        'Glaciate',
        'Icicle Spear',
        'Icicle Crash',
        'Ice Shard',
        'Freezy Frost',
        'Ice Punch',
        'Ice Hammer',
        'Avalanche',
        'Ice Fang',
        'Ice Burn',
        'Aurora Veil',
        'Aurora Beam',
        'Freeze-Dry',
        'Blizzard',
        'Ice Beam',
        'Ice Ball',
        'Freeze Shock',
        'Powder Snow',
        'HP Ice',
        'Triple Axel',
        'Glacial Lance',
        'Ice Spinner',
        'Chilly Reception',
        'Snowscape',
    ],
    Normal: [
        'Bide',
        'Hyper Voice',
        'Horn Attack',
        'Multi-Attack',
        'Celebrate',
        'Boomburst',
        'Natural Gift',
        'Nature Power',
        'Fury Attack',
        'Howl',
        'Frustration',
        'Hold Hands',
        'Tackle',
        'Hidden Power',
        'Leer',
        'Noble Roar',
        'Harden',
        'Happy Hour',
        'Helping Hand',
        'Cut',
        'Odor Sleuth',
        'Lock-On',
        'Foresight',
        'Body Slam',
        'Follow Me',
        'Pain Split',
        'Focus Energy',
        'Lovely Kiss',
        'Pay Day',
        'Block',
        'Chip Away',
        'Perish Song',
        'Tail Whip',
        'Swords Dance',
        'Lucky Chant',
        'Guillotine',
        'Play Nice',
        'Flash',
        'Flail',
        'Swift',
        'Sweet Scent',
        'Crush Claw',
        'Swallow',
        'Swagger',
        'Techno Blast',
        'Growth',
        'Pound',
        'Work Up',
        'Whirlwind',
        'Bind',
        'Weather Ball',
        'Supersonic',
        'Growl',
        'Present',
        'Protect',
        'Psych Up',
        'Pulverizing Pancake',
        'Horn Drill',
        'Defense Curl',
        'Bestow',
        'Belly Drum',
        'Quick Attack',
        'Feint',
        'Super Fang',
        'Rage',
        'False Swipe',
        'Rapid Spin',
        'Tail Slap',
        'Tri Attack',
        'Razor Wind',
        'Recover',
        'Recycle',
        'Reflect Type',
        'Refresh',
        'Relic Song',
        'Retaliate',
        'Return',
        'Revelation Dance',
        'Fake Out',
        'Facade',
        'Roar',
        'Baton Pass',
        'Transform',
        'Rock Climb',
        'Uproar',
        'Take Down',
        'ExtremeSpeed',
        'Extreme Speed',
        'Disable',
        'Extreme Evoboost',
        'After You',
        'Explosion',
        'Tickle',
        'Entrainment',
        'Hold Back',
        'Round',
        'Breakneck Blitz',
        'Endure',
        'Safeguard',
        'Judgment',
        'Me First',
        'Endeavor',
        'Scary Face',
        'Scratch',
        'Screech',
        'Encore',
        'Secret Power',
        'Mean Look',
        'Heal Bell',
        'Mega Kick',
        'Comet Punch',
        'SelfDestruct',
        'Self-Destruct',
        'Confide',
        'Egg Bomb',
        'Constrict',
        'Echoed Voice',
        'Mega Punch',
        'Headbutt',
        'Sharpen',
        'Glare',
        'Shell Smash',
        'ViceGrip',
        'Vice Grip',
        'Vise Grip',
        'Crush Grip',
        'Barrage',
        'Camouflage',
        'Substitute',
        'Conversion',
        'Simple Beam',
        'Sing',
        'Conversion 2',
        'Sketch',
        'Skull Bash',
        'Copycat',
        'Wrap',
        'Giga Impact',
        'Slack Off',
        'Slam',
        'Slash',
        'Metronome',
        'Sleep Talk',
        'Yawn',
        'Milk Drink',
        'Mimic',
        'Mind Reader',
        'SmellingSalt',
        'Smelling Salts',
        'Struggle',
        'SmokeScreen',
        'Smokescreen',
        'Minimize',
        'Snore',
        'Thrash',
        'SoftBoiled',
        'Soft-Boiled',
        'Head Charge',
        'Trump Card',
        'SonicBoom',
        'Sonic Boom',
        'Covet',
        'Hyper Beam',
        'Wish',
        'Tearful Look',
        'Double-Edge',
        'Double Team',
        'Spike Cannon',
        'Captivate',
        'Fury Swipes',
        'DoubleSlap',
        'Double Slap',
        'Spit Up',
        'Double Hit',
        'Splash',
        'Morning Sun',
        'Spotlight',
        'Laser Focus',
        'Attract',
        'Wring Out',
        'Stockpile',
        'Assist',
        'Stomp',
        'Last Resort',
        'Teeter Dance',
        'Dizzy Punch',
        'Strength',
        'Hyper Fang',
        'Acupressure',
        'Stuff Cheeks',
        'Teatime',
        'Court Change',
        'Terrain Pulse',
        'Veevee Volley',
        'Tera Blast',
        'Population Bomb',
        'Revival Blessing',
        'Doodle',
        'Fillet Away',
        'Raging Bull',
        'Shed Tail',
        'Tidy Up',
        'Hyper Drill',
    ],
    Poison: [
        'Acid',
        'Sludge Wave',
        'Sludge Bomb',
        'Sludge',
        'Baneful Bunker',
        'Coil',
        'Toxic',
        'Toxic Spikes',
        'Toxic Thread',
        'Belch',
        'Purify',
        'Clear Smog',
        'Cross Poison',
        'Smog',
        'Poison Sting',
        'PoisonPowder',
        'Poison Powder',
        'Poison Jab',
        'Poison Gas',
        'Poison Fang',
        'Gastro Acid',
        'Gunk Shot',
        'Venom Drench',
        'Venoshock',
        'Acid Armor',
        'Acid Spray',
        'Acid Downpour',
        'Poison Tail',
        'HP Poison',
        'Corrosive Gas',
        'Shell Side Arm',
        'Mortal Spin',
        'Noxious Torque',
    ],
    Psychic: [
        'Barrier',
        'Psystrike',
        'Psywave',
        'Guard Swap',
        'Future Sight',
        'Zen Headbutt',
        'Heal Block',
        'Speed Swap',
        'Calm Mind',
        'Heal Pulse',
        'Imprison',
        'Healing Wish',
        'Heart Stamp',
        'Meditate',
        'Trick',
        'Heart Swap',
        'Wonder Room',
        'Confusion',
        'Instruct',
        'Teleport',
        'Light Screen',
        'Telekinesis',
        'Shattered Psyche',
        'Genesis Supernova',
        'Reflect',
        'Magic Room',
        'Gravity',
        'Hyperspace Hole',
        'Stored Power',
        'Rest',
        'Hypnosis',
        'Cosmic Power',
        'Lunar Dance',
        'Extrasensory',
        'Luster Purge',
        'Trick Room',
        'Skill Swap',
        'Power Split',
        'Power Swap',
        'Power Trick',
        'Miracle Eye',
        'Mirror Coat',
        'Kinesis',
        'Magic Coat',
        'Dream Eater',
        'Prismatic Laser',
        'Role Play',
        'Mist Ball',
        'Psybeam',
        'Guard Split',
        'Ally Switch',
        'Psychic',
        'Psychic Fangs',
        'Psychic Terrain',
        'Synchronoise',
        'Glitzy Glow',
        'Psycho Boost',
        'Amnesia',
        'Psycho Cut',
        'Psycho Shift',
        'Psyshock',
        'Agility',
        'Photon Geyser',
        'Light That Burns The Sky',
        'HP Psychic',
        'Magic Powder',
        'Expanding Force',
        'Freezing Glare',
        'Eerie Spell',
        'Lumina Crash',
        'Twin Beam',
    ],
    Rock: [
        'Rock Throw',
        'Diamond Storm',
        'Ancient Power',
        'Stealth Rock',
        'Smack Down',
        'Sandstorm',
        'Rollout',
        'Rock Wrecker',
        'Rock Tomb',
        'Stone Edge',
        'Rock Slide',
        'Rock Polish',
        'Rock Blast',
        'Power Gem',
        'Wide Guard',
        'Head Smash',
        'Continental Crush',
        'Accelerock',
        'HP Rock',
        'Tar Shot',
        'Meteor Beam',
        'Salt Cure',
    ],
    Steel: [
        'Anchor Shot',
        'Autotomize',
        'Mirror Shot',
        'Sunsteel Strike',
        'Metal Sound',
        'Metal Claw',
        'Metal Burst',
        'Magnet Bomb',
        'King\'s Shield',
        'Iron Tail',
        'Iron Head',
        'Iron Defense',
        'Bullet Punch',
        'Smart Strike',
        'Heavy Slam',
        'Meteor Mash',
        'Gyro Ball',
        'Gear Up',
        'Gear Grind',
        'Flash Cannon',
        'Shift Gear',
        'Corkscrew Crash',
        'Doom Desire',
        'Steel Wing',
        'Splintered Stormshards',
        'Searing Sunraze Smash',
        'HP Steel',
        'Behemoth Blade',
        'Behemoth Bash',
        'Steel Roller',
        'Spin Out',
        'Make It Rain',
        'Gigaton Hammer',
    ],
    Water: [
        'Brine',
        'Rain Dance',
        'Surf',
        'Origin Pulse',
        'Oceanic Operetta',
        'Hydro Vortex',
        'Bouncy Bubble',
        'Water Gun',
        'Water Pledge',
        'Water Pulse',
        'Water Shuriken',
        'Water Sport',
        'Water Spout',
        'Waterfall',
        'Hydro Pump',
        'Whirlpool',
        'Hydro Cannon',
        'Muddy Water',
        'Steam Eruption',
        'Aqua Ring',
        'Aqua Tail',
        'Sparkling Aria',
        'Withdraw',
        'Scald',
        'Aqua Jet',
        'Clamp',
        'Soak',
        'Bubble',
        'Liquidation',
        'Dive',
        'Crabhammer',
        'Bubble Beam',
        'BubbleBeam',
        'Razor Shell',
        'Octazooka',
        'HP Water',
        'Snipe Shot',
        'Fishious Rend',
        'Life Dew',
        'Surging Strikes',
        'Flip Turn',
        'Splishy Splash',
        'Jet Punch',
        'Aqua Step',
        'Chilling Water',
        'Aqua Cutter',
        'Triple Dive',
    ],
    Shadow: [
        'Shadow Rush',
        'Shadow Blast',
        'Shadow Bolt',
        'Shadow Break',
        'Shadow Chill',
        'Shadow Down',
        'Shadow End',
        'Shadow Fire',
        'Shadow Half',
        'Shadow Hold',
        'Shadow Mist',
        'Shadow Panic',
        'Shadow Rave',
        'Shadow Shed',
        'Shadow Sky',
        'Shadow Storm',
        'Shadow Wave',
    ],
};
