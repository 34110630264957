import { movesByType, Types } from 'utils';
export const getMoveType = (move) => {
    for (const type in movesByType) {
        if (movesByType.hasOwnProperty(type)) {
            if (movesByType[type].some((value) => {
                return move.toLowerCase() === value.toLowerCase();
            })) {
                return Types[type];
            }
        }
    }
    return Types.Normal;
};
