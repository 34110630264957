var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Classes, Drawer, DrawerSize, Icon, Intent, Toaster } from '@blueprintjs/core';
import { css, cx } from 'emotion';
import * as React from 'react';
import { ImageUpload } from './ImageUpload';
import Dexie from 'dexie';
import { useDispatch, useSelector } from 'react-redux';
import { isDarkModeSelector } from 'selectors';
import { Skeleton } from './Skeletons';
import { toggleDialog } from 'actions';
const styles = {
    imagesDrawer: css `
        padding: 2rem;
    `,
    images: css `
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        min-height: 50vh;
        padding-bottom: 6rem;
        margin-top: 2rem;
        max-height: 100vh;
    `,
    image: css `
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: calc(25% - 1rem);
        height: 14rem;
        justify-content: center;
        align-items: center;
        position: relative;
    `,
    imageCaption: css `

    `,
    imageInner: css `
        object-fit: cover;
        width: 100%;
        height: 14rem;
    `,
    deleteIcon: css `
        margin-left: auto;
        cursor: pointer;
    `,
    input: css `
        width: 24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1rem;
    `,
    inputGroup: css `
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0.5rem;
        align-items: center;
        justify-content: space-between;
        background: rgb(22,22,22);
    `,
    layoutViewIcon: css `
        cursor: pointer;
        margin: 0.25rem;
    `,
};
class NuzlockeGeneratorDB extends Dexie {
    constructor() {
        super('NuzlockeGenerator');
        this.version(1).stores({
            images: `++id, image, name`
        });
        this.images = this.table('images');
    }
}
export const db = new NuzlockeGeneratorDB();
const uuid = require('uuid');
const userImages = new Set();
export const getImages = () => __awaiter(void 0, void 0, void 0, function* () {
    const images = yield db.images.toArray();
    console.log(images);
    return images;
});
var ImagesDrawerLayout;
(function (ImagesDrawerLayout) {
    ImagesDrawerLayout["List"] = "list";
    ImagesDrawerLayout["Grid"] = "grid";
})(ImagesDrawerLayout || (ImagesDrawerLayout = {}));
export function ImagesDrawerInner() {
    const [refresh, setRefresh] = React.useState(null);
    const [images, setImages] = React.useState();
    const [layoutView, setLayoutView] = React.useState(ImagesDrawerLayout.Grid);
    const isDarkMode = useSelector(isDarkModeSelector);
    React.useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () { return setImages(yield getImages()); }))();
        setRefresh(null);
    }, [refresh]);
    const setLayout = React.useCallback(() => {
        setLayoutView(layoutView === ImagesDrawerLayout.List ? ImagesDrawerLayout.Grid : ImagesDrawerLayout.List);
    }, [layoutView]);
    const deleteImage = (id) => () => __awaiter(this, void 0, void 0, function* () {
        const toaster = Toaster.create();
        try {
            const deletion = yield db.images
                .where('id').equals(id)
                .delete();
            setRefresh(id);
        }
        catch (e) {
            toaster.show({
                message: `Error deleting item ocurred. ${e}`,
                intent: Intent.DANGER,
            });
        }
    });
    return React.createElement("div", { className: cx('images-drawer', styles.imagesDrawer, isDarkMode && Classes.DARK) },
        React.createElement("div", { className: 'p-2 relative' },
            React.createElement(ImageUpload, { onSuccess: (image, fileName) => __awaiter(this, void 0, void 0, function* () {
                    const id = yield db.images.put({
                        image: image,
                        name: fileName,
                    });
                    setRefresh(id);
                }) }),
            React.createElement("span", null,
                React.createElement(Button, { className: styles.layoutViewIcon, onClick: setLayout, icon: layoutView === ImagesDrawerLayout.Grid ? 'list' : 'grid-view' }, layoutView === ImagesDrawerLayout.Grid ? 'List' : 'Grid'))),
        React.createElement("div", { className: styles.images }, images === null || images === void 0 ? void 0 : images.map(image => React.createElement("div", { key: image.id, className: styles.image },
            React.createElement("img", { className: styles.imageInner, src: image.image, alt: image.name, title: image.name }),
            React.createElement("div", { className: styles.inputGroup },
                React.createElement("input", { readOnly: true, className: cx(styles.input, Classes.INPUT), value: image.name }),
                (image === null || image === void 0 ? void 0 : image.id) && React.createElement(Icon, { className: styles.deleteIcon, onClick: deleteImage(image === null || image === void 0 ? void 0 : image.id), icon: 'trash', intent: Intent.DANGER }))))));
}
export function ImagesDrawer() {
    var _a;
    const isDarkMode = useSelector(isDarkModeSelector);
    const view = useSelector(state => state.view);
    const dispatch = useDispatch();
    const onClose = () => dispatch(toggleDialog('imageUploader'));
    return React.createElement(Drawer, { isOpen: (_a = view === null || view === void 0 ? void 0 : view.dialogs) === null || _a === void 0 ? void 0 : _a.imageUploader, size: DrawerSize.STANDARD, className: isDarkMode ? Classes.DARK : '', onClose: onClose },
        React.createElement(React.Suspense, { fallback: Skeleton },
            React.createElement(ImagesDrawerInner, null)));
}
