export const CREATE_CUSTOM_TYPE = 'CREATE_CUSTOM_TYPE';
export function createCustomType(typeInfo) {
    return {
        type: CREATE_CUSTOM_TYPE,
        typeInfo,
    };
}
export const DELETE_CUSTOM_TYPE = 'DELETE_CUSTOM_TYPE';
export function deleteCustomType(id) {
    return {
        type: DELETE_CUSTOM_TYPE,
        id,
    };
}
export const EDIT_CUSTOM_TYPE = 'EDIT_CUSTOM_TYPE';
export function editCustomType(id, typeInfo) {
    return {
        type: EDIT_CUSTOM_TYPE,
        id,
        typeInfo,
    };
}
