export const ADD_STAT = 'ADD_STAT';
export function addStat(stat) {
    return {
        type: ADD_STAT,
        stat,
    };
}
export const EDIT_STAT = 'EDIT_STAT';
export function editStat(id, key, value) {
    return {
        type: EDIT_STAT,
        id,
        key,
        value,
    };
}
export const DELETE_STAT = 'DELETE_STAT';
export function deleteStat(id) {
    return {
        type: DELETE_STAT,
        id,
    };
}
