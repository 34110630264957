export const locations = {
    Kanto: [
        'Route 1',
        'Route 2',
        'Route 3',
        'Route 4',
        'Route 5',
        'Route 6',
        'Route 7',
        'Route 8',
        'Route 9',
        'Route 10',
        'Route 11',
        'Route 12',
        'Route 13',
        'Route 14',
        'Route 15',
        'Route 16',
        'Route 17',
        'Route 18',
        'Route 19',
        'Route 20',
        'Route 21',
        'Route 22',
        'Route 23',
        'Route 24',
        'Route 25',
        'Route 26',
        'Route 27',
        'Route 28',
        'Celadon City',
        'Cerulean Cave',
        'Cerulean City',
        'Cinnabar Island',
        'Diglett\'s Cave',
        'Fuchsia City',
        'Mt. Moon',
        'Pallet Town',
        'Pewter City',
        'Pokémon Mansion',
        'Pokémon Tower',
        'Power Plant',
        'Rock Tunnel',
        'Safari Zone',
        'Saffron City',
        'Seafoam Islands',
        'Silph Co',
        'S.S. Anne',
        'Tohjo Falls',
        'Vermilion City',
        'Victory Road',
        'Viridian City',
        'Viridian Forest',
    ],
    Sevii: [
        'Berry Forest',
        'Bond Bridge',
        'Canyon Entrance',
        'Cape Brink',
        'Five Island',
        'Five Isle Meadow',
        'Four Island',
        'Green Path',
        'Icefall Cave',
        'Kindle Road',
        'Lost Cave',
        'Memorial Pillar',
        'Mt. Ember',
        'One Island',
        'Outcast Island',
        'Pattern Bush',
        'Resort Gorgeous',
        'Ruin Valley',
        'Seavault Canyon',
        'Tanoby Ruins',
        'Three Isle Port',
        'Trainer Tower',
        'Treasure Beach',
        'Water Labyrinth',
        'Water Path',
    ],
    Johto: [
        'Route 29',
        'Route 30',
        'Route 31',
        'Route 32',
        'Route 33',
        'Route 34',
        'Route 35',
        'Route 36',
        'Route 37',
        'Route 38',
        'Route 39',
        'Route 40',
        'Route 41',
        'Route 42',
        'Route 43',
        'Route 44',
        'Route 45',
        'Route 46',
        'Route 47',
        'Route 48',
        'Azalea Town',
        'Bell Tower',
        'Blackthorn City',
        'Burned Tower',
        'Cherrygrove City',
        'Cianwood City',
        'Cliff Cave',
        'Cliff Edge Gate',
        'Dark Cave',
        'Dragon\'s Den',
        'Ecruteak City',
        'Embedded Tower',
        'Goldenrod City',
        'Ice Path',
        'Ilex Forest',
        'Lake of Rage',
        'Mt. Mortar',
        'Mt. Silver',
        'National Park',
        'New Bark Town',
        'Olivine City',
        'Ruins of Alph',
        'Safari Zone Gate',
        'Slowpoke Well',
        'Sprout Tower',
        'Team Rocket HQ',
        'Tin Tower',
        'Union Cave',
        'Violet City',
        'Whirl Islands',
    ],
    Hoenn: [
        'Route 101',
        'Route 102',
        'Route 103',
        'Route 104',
        'Route 105',
        'Route 106',
        'Route 107',
        'Route 108',
        'Route 109',
        'Route 110',
        'Route 111',
        'Route 112',
        'Route 113',
        'Route 114',
        'Route 115',
        'Route 116',
        'Route 117',
        'Route 118',
        'Route 119',
        'Route 120',
        'Route 121',
        'Route 122',
        'Route 123',
        'Route 124',
        'Route 125',
        'Route 126',
        'Route 127',
        'Route 128',
        'Route 129',
        'Route 130',
        'Route 131',
        'Route 132',
        'Route 133',
        'Route 134',
        'Abandoned Ship',
        'Altering Cave',
        'Artisan Cave',
        'Battle Resort',
        'Battle Tower',
        'Birth Island',
        'Cave of Origin',
        'Desert Underpass',
        'Dewford Town',
        'Ever Grande City',
        'Faraway Island',
        'Fiery Path',
        'Fortree City',
        'Granite Cave',
        'Jagged Pass',
        'Lavaridge Town',
        'Lilycove City',
        'Littleroot Town',
        'Magma Hideout',
        'Marine Cave',
        'Meteor Falls',
        'Mirage Island',
        'Mirage Cave',
        'Mirage Forest',
        'Mirage Island',
        'Mirage Mountain',
        'Mirage Tower',
        'Mossdeep City',
        'Mt. Pyre',
        'Navel Rock',
        'New Mauville',
        'Pacifidlog Town',
        'Petalburg City',
        'Petalburg Woods',
        'Rustboro City',
        'Rusturf Tunnel',
        'Safari Zone',
        'Scorched Slab',
        'Sea Mauville',
        'Seafloor Cavern',
        'Sealed Chamber',
        'Shoal Cave',
        'Sky Pillar',
        'Slateport City',
        'Sootopolis City',
        'Southern Island',
        'Team Magma/Aqua Hideout',
        'Terra Cave',
        'Victory Road',
    ],
    Orre: [
        'Agate Village',
        'Cave Pokéspot',
        'Cipher Key Lair',
        'Cipher Lab',
        'Citadark Isle',
        'Deep Colosseum',
        'Gateon Port',
        'Kaminko\'s House',
        'Mt. Battle',
        'Oasis Pokéspot',
        'ONBS',
        'Orre Colosseum',
        'Outskirt Stand',
        'Phenac City',
        'Phenac Stadium',
        'Pokémon HQ Lab',
        'Prestige Precept Center',
        'Pyrite Bldg',
        'Pyrite Cave',
        'Pyrite Colosseum',
        'Pyrite Town',
        'Realgam Colosseum',
        'Realgam Tower',
        'Relic Cave',
        'Relic Stone',
        'Rock Pokéspot',
        'S.S. Libra',
        'Snagem Hideout',
        'The Under Subway',
        'The Under',
        'Under Colosseum',
    ],
    Sinnoh: [
        'Route 201',
        'Route 202',
        'Route 203',
        'Route 204',
        'Route 205',
        'Route 206',
        'Route 207',
        'Route 208',
        'Route 209',
        'Route 210',
        'Route 211',
        'Route 212',
        'Route 213',
        'Route 214',
        'Route 215',
        'Route 216',
        'Route 217',
        'Route 218',
        'Route 219',
        'Route 220',
        'Route 221',
        'Route 222',
        'Route 223',
        'Route 224',
        'Route 225',
        'Route 226',
        'Route 227',
        'Route 228',
        'Route 229',
        'Route 230',
        'Acuity Lakefront',
        'Canalave City',
        'Celestic Town',
        'Distortion World',
        'Eterna City',
        'Eterna Forest',
        'Floaroma Meadow',
        'Flower Paradise',
        'Fuego Ironworks',
        'Great Marsh',
        'Hall of Origin',
        'Hearthome City',
        'Iron Island',
        'Lake Acuity',
        'Lake Valor',
        'Lake Verity',
        'Lost Tower',
        'Mining Museum',
        'Mt. Coronet',
        'Newmoon Island',
        'Old Chateau',
        'Oreburgh City',
        'Oreburgh Gate',
        'Oreburgh Mine',
        'Pastoria City',
        'Pokémon League',
        'Ravaged Path',
        'Resort Area',
        'Ruins Maniac\'s Cave',
        'Ruins Maniac\'s Tunnel',
        'Sendoff Spring',
        'Snowpoint City',
        'Snowpoint Temple',
        'Solaceon Ruins',
        'Spear Pillar',
        'Stark Mountain',
        'Sunyshore City',
        'Trophy Garden',
        'Turnback Cave',
        'Twinleaf Town',
        'Valley Windworks',
        'Valor Lakefront',
        'Verity Lakefront',
        'Victory Road',
        'Wayward Cave',
        'Rocky Cave',
        'Grassland Cave',
        'Fountainspring Cave',
        'Spacious Cave',
        'Volcanic Cave',
        'Swampy Cave',
        'Dazzling Cave',
        'Stargleam Cave',
        'Whiteout Cave',
        'Icy Cave',
        'Glacier Cave',
        'Riverbank Cave',
        'Sandsear Cave',
        'Still-Water Cave',
        'Sunlit Cavern',
        'Big Bluff Cavern',
        'Bosgunk Cavern',
        'Typhlo Cavern',
    ],
    Unova: [
        'Route 1',
        'Route 2',
        'Route 3',
        'Route 4',
        'Route 5',
        'Route 6',
        'Route 7',
        'Route 8',
        'Route 9',
        'Route 10',
        'Route 11',
        'Route 12',
        'Route 13',
        'Route 14',
        'Route 15',
        'Route 16',
        'Route 17',
        'Route 18',
        'Route 19',
        'Route 20',
        'Route 21',
        'Route 22',
        'Route 23',
        'Abundant Shrine',
        'Accumula Town',
        'Aspertia City',
        'Castelia City',
        'Castelia Sewers',
        'Celestial Tower',
        'Challenger\'s Cave',
        'Chargestone Cave',
        'Clay Tunnel',
        'Cold Storage',
        'Desert Resort',
        'Dragonspiral Tower',
        'Dreamyard',
        'Driftveil City',
        'Driftveil Drawbridge',
        'Floccesy Ranch',
        'Giant Chasm',
        'Humilau City',
        'Icirrus City',
        'Liberty Island',
        'Lostlorn Forest',
        'Marvelous Bridge',
        'Mistralton Cave',
        'Moor of Icirrus',
        'N\'s Castle',
        'Nacrene City',
        'Nature Preserve',
        'Nature Sanctuary',
        'Nuvema Town',
        'P2 Laboratory',
        'Pinwheel Forest',
        'Relic Castle',
        'Relic Passage',
        'Reversal Mountain',
        'Seaside Cave',
        'Strange House',
        'Striaton City',
        'Twist Mountain',
        'Undella Bay',
        'Undella Town',
        'Underground Ruins',
        'Victory Road',
        'Village Bridge',
        'Virbank City',
        'Virbank Complex',
        'Wellspring Cave',
        'White Forest',
    ],
    Kalos: [
        'Route 2',
        'Route 3',
        'Route 4',
        'Route 5',
        'Route 6',
        'Route 7',
        'Route 8',
        'Route 9',
        'Route 10',
        'Route 11',
        'Route 12',
        'Route 13',
        'Route 14',
        'Route 15',
        'Route 16',
        'Route 17',
        'Route 18',
        'Route 19',
        'Route 20',
        'Route 21',
        'Route 22',
        'Ambrette Town',
        'Aquacorde Town',
        'Azure Bay',
        'Connecting Cave',
        'Couriway Town',
        'Cyllage City',
        'Frost Cavern',
        'Glittering Cave',
        'Laverre City',
        'Lost Hotel',
        'Lumiose City',
        'Parfum Palace',
        'Pokémon Village',
        'Reflection Cave',
        'Santalune City',
        'Santalune Forest',
        'Sea Spirit\'s Den',
        'Shalour City',
        'Snowbelle City',
        'Team Flare HQ',
        'Terminus Cave',
        'Tower of Mastery',
        'Unknown Dungeon',
        'Vaniville Town',
        'Victory Road',
    ],
    Alola: [
        'Route 1',
        'Route 2',
        'Route 3',
        'Route 4',
        'Route 5',
        'Route 6',
        'Route 7',
        'Route 8',
        'Route 9',
        'Route 10',
        'Route 11',
        'Route 12',
        'Route 13',
        'Route 14',
        'Route 15',
        'Route 16',
        'Route 17',
        'Aether Paradise',
        'Akala Outskirts',
        'Altar of the Moone',
        'Altar of the Sunne',
        'Ancient Poni Path',
        'Berry Fields',
        'Blush Mountain',
        'Brooklet Hill',
        'Diglett\'s Tunnel',
        'Dividing Peak Tunnel',
        'Exeggutor Island',
        'Haina Desert',
        'Hano Beach',
        'Hau\'oli Cemetery',
        'Hau\'oli City',
        'Heahea Beach',
        'Iki Town',
        'Kala\'e Bay',
        'Lake of the Moone',
        'Lake of the Sunne',
        'Lush Jungle',
        'Malie City',
        'Malie Garden',
        'Melemele Meadow',
        'Melemele Sea',
        'Memorial Hill',
        'Mount Hokulani',
        'Mount Lanakila',
        'Paniola Ranch',
        'Paniola Town',
        'Poni Breaker Coast',
        'Poni Coast',
        'Poni Gauntlet',
        'Poni Grove',
        'Poni Meadow',
        'Poni Plains',
        'Poni Wilds',
        'Resolution Cave',
        'Ruins of Abundance',
        'Ruins of Conflict',
        'Ruins of Hope',
        'Ruins of Life',
        'Sandy Cave',
        'Seafolk Village',
        'Seaward Cave',
        'Secluded Shore',
        'Tapu Village',
        'Ten Carat Hill',
        'Thrifty Megamart',
        'Ula\'ula Beach',
        'Ula\'ula Meadow',
        'Ultra Megalopolis',
        'Ultra Space',
        'Ultra Space Wilds',
        'Vast Poni Canyon',
        'Verdant Cavern',
        'Wela Volcano Park',
    ],
    Galar: [
        'Postwick',
        'Wedgehurst',
        'Motostoke',
        'Turrfield',
        'Hulbury',
        'Hammerlocke',
        'Stow-on-Side',
        'Ballonlea',
        'Circhester',
        'Spikemuth',
        'Wyndon',
        'Route 1',
        'Route 2',
        'Route 3',
        'Route 4',
        'Route 5',
        'Route 6',
        'Route 7',
        'Route 8',
        'Route 9',
        'Route 10',
        'Meetup Spot',
        'Rolling Fields',
        'Dappled Grove',
        'Watchtower Ruins',
        'East Lake Axewell',
        'West Lake Axewell',
        'Axew\'s Eye',
        'South Lake Miloch',
        'Giant\'s Seat',
        'North Lake Miloch',
        'Motostoke Riverbank',
        'Motostoke Outskirts',
        'Bridge Field',
        'Stony Wilderness',
        'Dusty Bowl',
        'Giant\'s Mirror',
        'Hammerlocke Hills',
        'Giant\'s Cap',
        'Lake of Outrage',
        'Galar Mine',
        'Galar Mine No. 2',
        'Glimwood Tangle',
        'Slumbering Weald',
        'Fields of Honor',
        'Soothing Wetlands',
        'Forest of Focus',
        'Challenge Beach',
        'Brawler\'s Cave',
        'Challenge Road',
        'Courageous Cavern',
        'Loop Lagoon',
        'Training Lowlands',
        'Warm-Up Tunnel',
        'Potbottom Desert',
        'Workout Sea',
        'Stepping-Stone Sea',
        'Insular Sea',
        'Honeycalm Sea',
        'Honeycalm Island',
        'Master Dojo',
        'Tower of Darkness',
        'Tower of Waters',
        'Slipper Slope',
        'Freezington',
        'Frostpoint Field',
        'Giant\'s Bed',
        'Old Cemetery',
        'Snowside Slope',
        'Tunnel to the Top',
        'Path to the Peak',
        'Crown Shrine',
        'Giant\'s Foot',
        'Roaring-Sea Caves',
        'Three-Piont Pass',
        'Frigid Sea',
        'Ballimere Lake',
        'Lakeside Cave',
        'Dynatree Hill',
        'Rock Peak Ruins',
        'Iceberg Ruins',
        'Iron Ruins',
        'Split-DecisiRuins',
        'Max Lair',
    ],
    Paldea: [
        'Mesagoza',
        'Los Platos',
        'Cabo Poco',
        'The Pokémon League',
        'Alfornada',
        'South Paldean Sea',
        'Cortondo',
        'South Province Area One',
        'South Province Area Two',
        'South Province Area Three',
        'South Province Area Four',
        'South Province Area Five',
        'South Province Area Six',
        'Inlet Grotto',
        'Porto Marinada',
        'Medali',
        'Cascarrafa',
        'Asado Desert',
        'West Province Area One',
        'West Province Area Two',
        'West Province Area Three',
        'West Paldean Sea',
        'Levincia',
        'Zapapico',
        'Artazon',
        'Dalizapa Passage',
        'East Province Area One',
        'East Province Area Two',
        'East Province Area Three',
        'East Paldean Sea',
        'Monteneverre',
        'Glaseado Mountain',
        'Glaseado Mountain North',
        'Glaseado Mountain South',
        'Tagtree Thicket',
        'Casseroya Lake',
        'North Province Area One',
        'North Province Area Two',
        'North Province Area Three',
        'North Paldean Sea',
        'Area Zero',
        'Kitakami Road',
        'Mossui Town',
        'Apple Hills',
        'Reveler\'s Road',
        'Mossfell Confluence',
        'Fellhorn Gorge',
        'Loyalty Plaza',
        'Infernal Pass',
        'Oni Mountain',
        'Dreaded Den',
        'Oni\'s Maw',
        'Chilling Waterhead',
        'Crystal Pool',
        'Wistful Fields',
        'Paradise Barrens',
        'Kitakami Wilds',
        'Timeless Woods',
        'Canyon Biome',
        'Canyon Plaza',
        'Central Plaza',
        'Chargestone Cavern',
        'Coastal Biome',
        'Coastal Plaza',
        'Polar Biome',
        'Polar Plaza',
        'Savanna Biome',
        'Savanna Plaza',
        'Torchlit Labyrinth',
    ],
    Misc: [
        'Mystery Gift',
        'Fateful Encounter',
    ]
};
export const listOfLocations = [
    ...locations.Kanto,
    ...locations.Sevii,
    ...locations.Johto,
    ...locations.Hoenn,
    ...locations.Orre,
    ...locations.Sinnoh,
    ...locations.Unova,
    ...locations.Kalos,
    ...locations.Alola,
    ...locations.Galar,
    ...locations.Paldea,
    ...locations.Misc,
];
