export const matchNatureToToxtricityForme = (nature) => {
    console.log(nature);
    if ([
        'Adamant',
        'Brave',
        'Docile',
        'Hardy',
        'Hasty',
        'Impish',
        'Jolly',
        'Lax',
        'Naive',
        'Naughty',
        'Quirky',
        'Rash',
        'Sassy',
    ].includes(nature)) {
        return 'Amped';
    }
    else {
        return 'Lowkey';
    }
};
