export const ADD_RULE = 'ADD_RULE';
export function addRule() {
    return {
        type: ADD_RULE,
    };
}
export const EDIT_RULE = 'EDIT_RULE';
export function editRule(target, rule) {
    return {
        type: EDIT_RULE,
        target,
        rule,
    };
}
export const DELETE_RULE = 'DELETE_RULE';
export function deleteRule(target) {
    return {
        type: DELETE_RULE,
        target,
    };
}
export const RESET_RULES = 'RESET_RULES';
export function resetRules() {
    return {
        type: RESET_RULES,
    };
}
