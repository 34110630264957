import { noop } from './noop';
export class DeepSet {
    constructor(arr = [], key) {
        this.set = arr;
        this.key = key || 'name';
    }
    add(item, key = this.key) {
        if (this.set.some((i) => i[key] === item[key])) {
            noop();
        }
        else {
            this.set.push(item);
        }
        return new DeepSet(this.set);
    }
    overwrite(item, edits, key = this.key) {
        const foundItem = this.set.find((i) => i[key] === item[key]);
        if (foundItem) {
            const newItem = Object.assign(Object.assign({}, foundItem), edits);
            this.set.splice(this.set.indexOf(foundItem), 1, newItem);
            return new DeepSet(this.set);
        }
        return new DeepSet(this.set);
    }
    delete(item, key = this.key) {
        this.set = this.set.filter((i) => i[key] !== item[key]);
    }
    get(key) {
        return this.set.find((i) => i[this.key] === key);
    }
    has(item, key = this.key) {
        return this.set.some((i) => i[key] === item[key]);
    }
    toArray() {
        return this.set;
    }
    static cheapCompare(o, i) {
        return JSON.stringify(o) === JSON.stringify(i);
    }
}
