export var Forme;
(function (Forme) {
    Forme["Normal"] = "Normal";
    Forme["A"] = "a";
    Forme["B"] = "b";
    Forme["C"] = "c";
    Forme["D"] = "d";
    Forme["E"] = "e";
    Forme["F"] = "f";
    Forme["G"] = "g";
    Forme["H"] = "h";
    Forme["I"] = "i";
    Forme["J"] = "j";
    Forme["K"] = "k";
    Forme["L"] = "l";
    Forme["M"] = "m";
    Forme["N"] = "n";
    Forme["O"] = "o";
    Forme["P"] = "p";
    Forme["Q"] = "q";
    Forme["R"] = "r";
    Forme["S"] = "s";
    Forme["T"] = "t";
    Forme["U"] = "u";
    Forme["V"] = "v";
    Forme["W"] = "w";
    Forme["X"] = "x";
    Forme["Y"] = "y";
    Forme["Z"] = "z";
    Forme["!"] = "exclamation";
    Forme["?"] = "question";
    Forme["Mega"] = "mega";
    Forme["Mega-X"] = "mega-x";
    Forme["Mega-Y"] = "mega-y";
    Forme["Alolan"] = "alola";
    Forme["Galarian"] = "galar";
    Forme["Hisuian"] = "hisui";
    Forme["Paldean"] = "paldea";
    Forme["Gigantamax"] = "gigantamax";
    Forme["PaldeanBlaze"] = "paldea-blaze";
    Forme["PaldeanAqua"] = "paldea-aqua";
    Forme["GreenPlumage"] = "green-plumage";
    Forme["BluePlumage"] = "blue-plumage";
    Forme["YellowPlumage"] = "yellow-plumage";
    Forme["WhitePlumage"] = "white-plumage";
    Forme["10%"] = "10-percent";
    Forme["50%"] = "50-percent";
    Forme["Complete"] = "complete";
    Forme["Sunny"] = "sunny";
    Forme["Rainy"] = "rainy";
    Forme["Snowy"] = "snowy";
    Forme["Summer"] = "summer";
    Forme["Spring"] = "spring";
    Forme["Autumn"] = "autumn";
    Forme["Winter"] = "winter";
    Forme["Baile"] = "baile";
    Forme["Pa'u"] = "pa-u";
    Forme["Pom-Pom"] = "pom-pom";
    Forme["Sensu"] = "sensu";
    Forme["Dawn Wings"] = "dawn-wings";
    Forme["Dusk Mane"] = "dusk-mane";
    Forme["Ultra"] = "ultra";
    Forme["Plant"] = "plant";
    Forme["Sandy"] = "sandy";
    Forme["Trash"] = "trash";
    Forme["Sky"] = "sky";
    Forme["Origin"] = "origin";
    Forme["Attack"] = "attack";
    Forme["Defense"] = "defense";
    Forme["Speed"] = "speed";
    Forme["East Sea"] = "east";
    Forme["West Sea"] = "west";
    Forme["Lowkey"] = "lowkey";
    Forme["AmpedUp"] = "amped";
    Forme["Amped"] = "amped";
    Forme["White"] = "white";
    Forme["Black"] = "black";
    Forme["Mow"] = "mow";
    Forme["Wash"] = "wash";
    Forme["Frost"] = "frost";
    Forme["Fan"] = "fan";
    Forme["Heat"] = "heat";
    Forme["Hail"] = "hail";
    Forme["IceRider"] = "ice-rider";
    Forme["ShadowRider"] = "shadow-rider";
    Forme["School"] = "school";
    Forme["Pirouette"] = "pirouette";
    Forme["Therian"] = "therian";
    Forme["Wellspring"] = "wellspring";
    Forme["Heartflame"] = "heartflame";
    Forme["Cornerstone"] = "cornerstone";
})(Forme || (Forme = {}));
