var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { Button, Intent, Toaster } from '@blueprintjs/core';
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
const onUpload = ({ onSuccess, onError }) => (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
    const toaster = Toaster.create();
    if (!file) {
        onError && onError(e);
        toaster.show({
            message: 'No file detected.',
            intent: Intent.DANGER,
        });
        return;
    }
    const size = (file === null || file === void 0 ? void 0 : file.size) / 1024 / 1024;
    if (size > 0.5) {
        toaster.show({
            message: `File size of 500KB exceeded. File was ${size.toFixed(2)}MB`,
            intent: Intent.DANGER,
        });
    }
    else {
        try {
            const image = yield toBase64(file);
            onSuccess && onSuccess(image, file === null || file === void 0 ? void 0 : file.name);
            console.log(image);
            toaster.show({
                message: 'Upload successful!',
                intent: Intent.SUCCESS,
            });
        }
        catch (e) {
            onError && onError(e);
            toaster.show({
                message: `Error in parsing file. ${e}`,
                intent: Intent.DANGER,
            });
        }
    }
});
export function ImageUpload({ onSuccess, onError }) {
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { icon: 'upload', style: { position: 'relative', cursor: 'pointer', } },
            "Upload Image",
            React.createElement("input", { accept: 'image/*', style: { cursor: 'pointer', opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }, onChange: onUpload({ onSuccess, onError }), type: 'file' })));
}
