import { locations } from 'utils';
import { getGameRegion, Region } from './getGameRegion';
import { Generation, getGameGeneration } from './getGameGeneration';
export function getEncounterMap(game) {
    const region = getGameRegion(game);
    const generation = getGameGeneration(game);
    if (region === Region.Kanto && generation === Generation.Gen1) {
        return locations.Kanto;
    }
    if (region === Region.Kanto && generation === Generation.Gen3) {
        return [...locations.Kanto, ...locations.Sevii];
    }
    if (region === Region.Johto) {
        return [...locations.Johto, ...locations.Kanto];
    }
    if (region === Region.Hoenn) {
        return locations.Hoenn;
    }
    if (region === Region.Sinnoh) {
        return locations.Sinnoh;
    }
    if (region === Region.Unova) {
        return locations.Unova;
    }
    if (region === Region.Kalos) {
        return locations.Kalos;
    }
    if (region === Region.Alola) {
        return locations.Alola;
    }
    if (region === Region.Galar) {
        return locations.Galar;
    }
    if (region === Region.Orre) {
        return locations.Orre;
    }
    if (region === Region.Paldea) {
        return locations.Paldea;
    }
    return ['This game does not have location info.'];
}
