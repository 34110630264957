var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addForme, speciesToNumber, getForme } from 'utils';
import { capitalize, significantGenderDifferenceList, wrapImageInCORS, normalizeSpeciesName } from 'utils';
import { getIconFormeSuffix } from './getIconFormeSuffix';
import { getImages } from 'components/Shared/ImagesDrawer';
const handleTcgTransforms = (species, gender) => {
    if (gender === 'Female') {
        if (species && significantGenderDifferenceList.includes(species))
            return `${species}-f`;
    }
    return species;
};
const getGameName = (name) => {
    if (name === 'Red' || name === 'Blue')
        return 'rb';
    if (name === 'Ruby' ||
        name === 'Sapphire' ||
        name === 'FireRed' ||
        name === 'LeafGreen' ||
        name === 'Emerald') {
        return 'emerald';
    }
    if (name === 'Diamond' || name === 'Pearl' || name === 'Platinum')
        return 'dp';
    if (name === 'HeartGold' || name === 'SoulSilver')
        return 'hgss';
    if (name === 'Black' || name === 'White' || name === 'White 2' || name === 'Black 2')
        return 'blackwhite';
    if (name === 'X' || name === 'Y' || name === 'OmegaRuby' || name === 'AlphaSapphire')
        return 'xy';
    if (name === 'Sun' ||
        name === 'Moon' ||
        name === 'Ultra Sun' ||
        name === 'Ultra Moon' ||
        name === 'Colosseum' ||
        name === 'XD Gale of Darkness')
        return 'sunmoon';
    if (name === 'Green' ||
        name === 'Gold' ||
        name === 'Silver' ||
        name === 'Yellow' ||
        name === 'Crystal') {
        return name.toLowerCase();
    }
    if (name === 'Sword' || name === 'Shield' || name === 'Brilliant Diamond' || name === 'Shining Pearl') {
        return 'swordshield';
    }
    return 'sm';
};
const getGameNameSerebii = (name) => {
    switch (name) {
        case 'Black':
        case 'Black 2':
        case 'White':
        case 'White 2':
            return 'BW';
        case 'Gold':
            return 'Gold';
        case 'Silver':
            return 'Silver';
        case 'Crystal':
            return 'Crystal';
        case 'Ruby':
        case 'Sapphire':
            return 'RuSa';
        case 'FireRed':
        case 'LeafGreen':
            return 'FRLG';
        case 'Emerald':
            return 'Em';
        case 'Diamond':
        case 'Pearl':
        case 'Platinum':
            return 'DP';
        case 'HeartGold':
        case 'SoulSilver':
            return 'HGSS';
        case 'X':
        case 'Y':
            return 'XY';
        case 'Sword':
        case 'Shield':
        case 'Brilliant Diamond':
        case 'Shining Pearl':
            return 'swordshield';
        default:
            return 'SM';
    }
};
export function getPokemonImage({ customImage, forme, species, name, style, shiny, editor, gender, egg, }) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const regularNumber = speciesToNumber(species || 'Ditto');
        const leadingZerosNumber = (speciesToNumber(species || 'Ditto') || 0)
            .toString()
            .padStart(3, '0');
        if (customImage) {
            const images = yield getImages();
            const selectedImage = (_a = images.find(img => img.name === customImage)) === null || _a === void 0 ? void 0 : _a.image;
            if (selectedImage) {
                return `url(${selectedImage})`;
            }
            if (customImage.startsWith('http')) {
                return yield wrapImageInCORS(customImage);
            }
            return `url(${customImage})`;
        }
        if (editor === null || editor === void 0 ? void 0 : editor.temtemMode) {
            return `url(img/temtem/${species === null || species === void 0 ? void 0 : species.trim()}.png)`;
        }
        if (egg) {
            return 'url(img/egg.jpg)';
        }
        if ((style === null || style === void 0 ? void 0 : style.spritesMode) &&
            (name === 'Black' ||
                name === 'Emerald' ||
                name === 'Ruby' ||
                name === 'Sapphire' ||
                name === 'White' ||
                name === 'Black 2' ||
                name === 'White 2' ||
                name === 'X' ||
                name === 'Y' ||
                name === 'OmegaRuby' ||
                name === 'AlphaSapphire' ||
                name === 'Sun' ||
                name === 'Moon' ||
                name === 'Ultra Sun' ||
                name === 'Ultra Moon' ||
                name === 'Sword' ||
                name === 'Shield' ||
                name === 'Let\'s Go Eevee' ||
                name === 'Let\'s Go Pikachu' ||
                name === 'Colosseum' ||
                name === 'XD Gale of Darkness')) {
            if (!shiny) {
                const url = `https://www.serebii.net/${getGameName(name)}/pokemon/${leadingZerosNumber}${getForme(forme)}.png`;
                return yield wrapImageInCORS(url);
            }
            else {
                const url = `https://www.serebii.net/Shiny/${capitalize(getGameNameSerebii(name))}/${leadingZerosNumber}.png`;
                return yield wrapImageInCORS(url);
            }
        }
        if ((style === null || style === void 0 ? void 0 : style.spritesMode) && (name === 'Scarlet' || name === 'Violet')) {
            if (!shiny) {
                const url = `https://serebii.net/scarletviolet/pokemon/new/${leadingZerosNumber}.png`;
                return yield wrapImageInCORS(url);
            }
            else {
                const url = `https://serebii.net/Shiny/SV/new/${leadingZerosNumber}.png`;
                return yield wrapImageInCORS(url);
            }
        }
        if ((style === null || style === void 0 ? void 0 : style.spritesMode) && (name === 'LeafGreen' || name === 'FireRed')) {
            if (!shiny) {
                const url = `https://img.pokemondb.net/sprites/firered-leafgreen/normal/${normalizeSpeciesName(species)}.png`;
                return yield wrapImageInCORS(url);
            }
            else {
                const url = `https://img.pokemondb.net/sprites/firered-leafgreen/shiny/${normalizeSpeciesName(species)}.png`;
                return yield wrapImageInCORS(url);
            }
        }
        if (style === null || style === void 0 ? void 0 : style.spritesMode) {
            const url = shiny
                ? `https://www.serebii.net/Shiny/${getGameNameSerebii(name)}/${leadingZerosNumber}.png`
                : `https://www.serebii.net/pokearth/sprites/${getGameName(name)}/${leadingZerosNumber}.png`;
            return yield wrapImageInCORS(url);
        }
        if ((style === null || style === void 0 ? void 0 : style.teamImages) === 'sugimori') {
            if ([521, 592, 593, 668, 678].includes(regularNumber || 0) &&
                (gender === 'f' || gender === 'Female')) {
                return `url(img/sugimori/female/${regularNumber}${getIconFormeSuffix(forme)}.png)`;
            }
            return `url(img/sugimori/${regularNumber}${getIconFormeSuffix(forme)}.png)`;
        }
        if ((style === null || style === void 0 ? void 0 : style.teamImages) === 'dream world') {
            return `url(img/dw/${regularNumber || 1}.svg)`;
        }
        const handleMimeJr = (s) => (s === 'Mime Jr.' ? 'mime-jr' : s);
        if ((style === null || style === void 0 ? void 0 : style.teamImages) === 'shuffle') {
            return `url(img/shuffle/${(handleMimeJr(species) || 'Ditto')
                .trim()
                .replace(/\'/g, '')
                .replace(/\s/g, '-')
                .replace(/\./g, '-')
                .toLocaleLowerCase()}${getIconFormeSuffix(forme)}.png)`;
        }
        if ((style === null || style === void 0 ? void 0 : style.teamImages) === 'tcg') {
            return `url(img/tcg/${(handleTcgTransforms(addForme((species || '').replace(/\s/g, '').replace(/'/g, ''), forme), gender) || 'missingno').toLowerCase()}.jpg)`;
        }
        if (species === 'Dugtrio' && forme === 'Alolan' && shiny) {
            return 'url(img/alolan-dugtrio-shiny.jpg)';
        }
        if (species === 'Gyarados' && shiny) {
            return 'url(img/gyarados-shiny.jpg)';
        }
        if (species === 'Indeedee' && gender === 'Male') {
            return 'url(img/indeedee-m.jpg)';
        }
        if (species === 'Basculegion' && gender === 'Female') {
            return 'url(img/basculegion-f.jpg)';
        }
        return `url(img/${(addForme((species || '').trim().replace(/\s/g, '-').replace(/'/g, '').replace(/:/g, '-'), forme) ||
            'missingno').toLowerCase()}.jpg)`;
    });
}
export const stripURLCSS = (str) => str.replace(/url\(/g, '').replace(/\)/g, '');
