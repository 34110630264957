export const NEW_NUZLOCKE = 'NEW_NUZLOCKE';
export function newNuzlocke(data, { isCopy }) {
    return {
        type: NEW_NUZLOCKE,
        data,
        isCopy,
    };
}
export const DELETE_NUZLOCKE = 'DELETE_NUZLOCKE';
export function deleteNuzlocke(id) {
    return {
        type: DELETE_NUZLOCKE,
        id,
    };
}
export const SWITCH_NUZLOCKE = 'SWITCH_NUZLOCKE';
export function switchNuzlocke(id) {
    return {
        type: SWITCH_NUZLOCKE,
        id,
    };
}
export const UPDATE_NUZLOCKE = 'UPDATE_NUZLOCKE';
export function updateNuzlocke(id, data) {
    return {
        type: UPDATE_NUZLOCKE,
        id,
        data,
    };
}
export const UPDATE_SWITCH_NUZLOCKE = 'UPDATE_SWITCH_NUZLOCKE';
export function updateSwitchNuzlocke(id, newId, data) {
    return {
        type: UPDATE_SWITCH_NUZLOCKE,
        id,
        newId,
        data,
    };
}
