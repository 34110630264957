export var Types;
(function (Types) {
    Types["Grass"] = "Grass";
    Types["Fire"] = "Fire";
    Types["Water"] = "Water";
    Types["Electric"] = "Electric";
    Types["Rock"] = "Rock";
    Types["Ground"] = "Ground";
    Types["Steel"] = "Steel";
    Types["Flying"] = "Flying";
    Types["Poison"] = "Poison";
    Types["Psychic"] = "Psychic";
    Types["Fighting"] = "Fighting";
    Types["Bug"] = "Bug";
    Types["Fairy"] = "Fairy";
    Types["Normal"] = "Normal";
    Types["Ice"] = "Ice";
    Types["Dragon"] = "Dragon";
    Types["Dark"] = "Dark";
    Types["Ghost"] = "Ghost";
    Types["Shadow"] = "Shadow";
    Types["Neutral"] = "Neutral";
    Types["Crystal"] = "Crystal";
    Types["Digital"] = "Digital";
    Types["Earth"] = "Earth";
    Types["Melee"] = "Melee";
    Types["Mental"] = "Mental";
    Types["Toxic"] = "Toxic";
    Types["Wind"] = "Wind";
    Types["Nature"] = "Nature";
})(Types || (Types = {}));
export const isTemTemType = (type) => {
    return (type === Types.Neutral ||
        type === Types.Crystal ||
        type === Types.Digital ||
        type === Types.Earth ||
        type === Types.Melee ||
        type === Types.Mental ||
        type === Types.Toxic ||
        type === Types.Wind ||
        type === Types.Nature);
};
export const getListOfTypes = (customTypes, includeTemTemTypes = true) => [
    'None',
    Types.Bug,
    Types.Crystal,
    Types.Dark,
    Types.Digital,
    Types.Dragon,
    Types.Earth,
    Types.Electric,
    Types.Fairy,
    Types.Fighting,
    Types.Fire,
    Types.Flying,
    Types.Ghost,
    Types.Grass,
    Types.Ground,
    Types.Ice,
    Types.Melee,
    Types.Mental,
    Types.Nature,
    Types.Neutral,
    Types.Normal,
    Types.Poison,
    Types.Psychic,
    Types.Rock,
    Types.Shadow,
    Types.Steel,
    Types.Toxic,
    Types.Water,
    Types.Wind,
    ...customTypes.map((c) => c.type),
].filter((t) => (includeTemTemTypes ? true : !isTemTemType(t)));
